import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import LoginInput from "../components/common/input/LoginInput";
import { useAuthActions } from "../domains/auth/hook/useAuthActions";
import { UserSignUpInfo } from "../domains/auth/interface";
import { REGEX_FORMAT } from "../utils/regex";

export default function SignUpPage() {
  const { signUp, errorMessages, setErrorMessages } = useAuthActions();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      terms: false,
      email: "",
      password: "",
    },
  });

  const emailPattern = REGEX_FORMAT.EMAIL;

  const watchTerms = watch("terms");

  const isInputValid =
    Object.keys(dirtyFields).length > 0 &&
    Object.keys(errors).length === 0 &&
    watchTerms;

  const onSubmit = async (data: FieldValues) => {
    if (!isInputValid) {
      return;
    }

    const { email, password } = data;

    const signUpInfo: UserSignUpInfo = {
      serviceName: "Twinworld",
      email,
      password,
    };

    await signUp(signUpInfo).then(() => {
      navigate("/email-verification", { state: { email } });
    });
  };

  return (
    <section className="flex flex-col items-center w-full mx-auto border-t border-twinworldGray-200 w-[375px]">
      <h2 className="text-[20px] font-semibold leading-[25px] px-[56px] text-center mt-[64px]">
        Create a New Account
      </h2>
      <form
        className="flex flex-col items-center px-[18px] mt-[40px] gap-4 md:w-[482px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <LoginInput
          type="email"
          name="email"
          register={register}
          placeholder="Email"
          options={{
            required: true,
            maxLength: {
              value: 50,
              message: "* X Max length of 50 is allowed",
            },
            pattern: {
              value: emailPattern,
              message: "* Email address must be a valid address",
            },
          }}
          onClick={() => {
            setErrorMessages((prev) => ({
              ...prev,
              accountError: "",
            }));
          }}
          isError={
            !!errors?.email?.message ||
            !!errorMessages.accountError ||
            !!errorMessages.signUp
          }
        />
        {(errors?.email?.message ||
          errorMessages.accountError ||
          errorMessages.signUp) && (
          <p className="px-[16px] text-twinworldRed text-[14px] text-left w-full flex whitespace-nowrap">
            {errorMessages.accountError ||
              errorMessages.signUp ||
              errors?.email?.message?.toString()}
          </p>
        )}

        <LoginInput
          type="password"
          name="password"
          register={register}
          placeholder="Password"
          options={{
            required: true,
            minLength: {
              value: 8,
              message: "* Password should be more than 8 letters.",
            },
            pattern: {
              value: REGEX_FORMAT.PASSWORD,
              message:
                "* Ensure that password contains both letters and numbers.",
            },
          }}
          onClick={() => {
            setErrorMessages((prev) => ({
              ...prev,
              wrongPassword: "",
            }));
          }}
          isError={
            !!errors?.password?.message ||
            !!errorMessages.wrongPassword ||
            !!errorMessages.accountError
          }
        />
        {(errors?.password || errorMessages.wrongPassword) && (
          <p className="px-[16px] text-twinworldRed text-[14px] text-left w-full flex">
            {errorMessages.wrongPassword ||
              errors?.password?.message?.toString()}
          </p>
        )}
        <div className="flex items-center w-full h-full mt-[24px] ml-[30px]">
          <input
            type="checkbox"
            {...register("terms")}
            className="w-[16px] h-[16px] mr-2 cursor-pointer"
          />

          <div
            onClick={() => window.open("/terms-and-conditions/service")}
            className="text-twinworldGray-650 text-[14px] font-medium cursor-pointer underline"
          >
            Terms and conditions
          </div>
        </div>
        <button
          type="submit"
          className="flex items-center justify-center w-[339px] py-[19px] bg-black rounded-full mt-[48px] text-[16px] text-white font-medium 
           disabled:border-twinworldGray-500 disabled:border-[1.5px] disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:font-medium disabled:cursor-not-allowed"
          disabled={!isInputValid}
        >
          Sign Up
        </button>
      </form>
    </section>
  );
}
