import { Fragment } from "react";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import ScrollToTopButton from "../common/Button/ScrollToTopButton";

import MobileCoinsHistoryItem from "./MobileCoinsHistoryItem";
import MobileNoContentIcon from "./icons/MobileNoContentIcon";

export default function MobileCoinsHistory() {
  const { useInfiniteCoinsUsageHistory } = useAuthActions();
  const { data, fetchNextPage } = useInfiniteCoinsUsageHistory(20, 0);
  const { isMobile } = useDeviceBreakPoint();

  return (
    <div className="w-full mt-[35px]">
      {data?.pages[0].length !== 0 ? (
        data &&
        data?.pages[0].length > 0 &&
        data?.pages.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {page?.map((history) => (
              <MobileCoinsHistoryItem
                key={history.id}
                description={history.description}
                createdAt={history.createdAt}
                type={history.type}
                value={history.value}
              />
            ))}
          </Fragment>
        ))
      ) : (
        <div className="flex flex-col items-center gap-[18px] mt-[35px]">
          <MobileNoContentIcon />
          <div className="text-[14px] font-normal leading-none text-twinworldGray-400">
            You have no coins history.
          </div>
        </div>
      )}

      {data && data?.pages[0].length > 0 && (
        <div className="flex justify-center w-full min-w-[339px] border-t border-[#EFEFEF]">
          <button
            type="button"
            className="px-8 py-3 mt-[43px] border-[1.5px] border-black rounded-full font-semibold bg-twinworldPrimary-200"
            onClick={() => fetchNextPage()}
          >
            more
          </button>
        </div>
      )}
      {!isMobile && <ScrollToTopButton />}
    </div>
  );
}
