type Props = {
  description: string;
  createdAt: string;
  type: string;
  value: number;
};

export default function MobileCoinsHistoryItem({
  description,
  createdAt,
  type,
  value,
}: Props) {
  return (
    <div className="flex flex-col py-6 min-w-[339px] border-t border-[#EFEFEF] w-full">
      <span className="font-normal text-twinworldGray-600 text-[14px] leading-4">
        {createdAt}
      </span>
      <span className="font-medium text-[16px] text-twinworldGray-750 mt-3">
        {description}
      </span>
      <div className="flex ml-auto mt-6">
        <span
          className={`font-medium text-[16px] ${
            type === "지급" ? "text-[#6791D0]" : "text-[#FF4242]"
          }`}
        >
          {type === "지급" ? `+` : `-`}
        </span>
        <span
          className={`font-medium text-[16px] ml-[4px] ${
            type === "지급" ? "text-[#6791D0]" : "text-[#FF4242]"
          }`}
        >
          {value.toLocaleString()}C
        </span>
      </div>
    </div>
  );
}
