export default function CoinIcon() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="17" fill="#FF9900" />
      <g clipPath="url(#clip0_2818_29564)">
        <path
          d="M17.2833 10.9944C14.3744 11.0039 12.4572 13.2328 12.4572 17.1711C12.4572 21.1094 14.4028 23.3289 17.2644 23.3478C19.3989 23.3383 20.995 22.1766 21.3728 20.1461L25.3017 20.1744C24.8767 23.6311 21.9678 26.7855 17.2172 26.7855C12.2022 26.7666 8.5 23.2439 8.5 17.1616C8.5 11.0794 12.2683 7.55664 17.1983 7.55664C21.5144 7.55664 24.7633 10.0216 25.2828 14.3189H21.3539C21.0422 12.2505 19.4556 11.0039 17.2739 10.985L17.2833 10.9944Z"
          fill="#FFF493"
        />
      </g>
      <defs>
        <clipPath id="clip0_2818_29564">
          <rect
            width="16.7828"
            height="19.21"
            fill="white"
            transform="translate(8.5 7.55664)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
