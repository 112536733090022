export default function RedeemCard() {
  return (
    <section className="flex flex-col xl:flex-row w-full xl:gap-[84px] pt-[36px] pb-[44px] xl:py-[60px] px-6 xl:px-[80px] rounded-[15px] bg-[#EFF4F9] shadow-[2px_2px_4px_0_rgba(0,0,0,0.25)]">
      <div className="hidden xl:block w-[291px] h-[201px] xl:w-[386px] xl:h-[270px] rounded-[35px] shadow-[4px_4px_8px_0px_rgba(0,0,0,0.25)]">
        <picture>
          <source
            srcSet="/images/card/amazon_gift_card@2x.webp 2x, /images/card/amazon_gift_card.webp 1x"
            type="image/webp"
          />
          <img
            src="/images/card/amazon_gift_card.webp"
            alt="amazon gift card"
          />
        </picture>
      </div>
      <div className="flex flex-col">
        <h4 className="text-[16px] leading-[16px] xl:text-[24px] xl:leading-[24px] font-medium xl:text-twinworldGray-650">
          Amazon Gift Card $30
        </h4>
        <p className="mt-[14px] text-[24px] leading-7 xl:text-[32px] xl:leading-8 font-medium">
          30,000C
        </p>

        <div className="w-[291px] h-[201px] xl:hidden rounded-[35px] shadow-[4px_4px_8px_0px_rgba(0,0,0,0.25)] mt-8 mb-[28px]">
          <picture>
            <source
              srcSet="/images/card/amazon_gift_card@2x.webp 2x, /images/card/amazon_gift_card.webp 1x"
              type="image/webp"
            />
            <img
              src="/images/card/amazon_gift_card.webp"
              alt="amazon gift card"
            />
          </picture>
        </div>

        <button
          type="button"
          className={`w-[291px] xl:w-[300px] px-[50px] py-[18px] xl:py-[20px] border-[1.5px] xl:border-2 rounded-full font-semibold border-twinworldGray-500 bg-twinworldSecondary-200 text-twinworldGray-500 cursor-not-allowed xl:mt-auto`}
          disabled
        >
          Coming Soon
        </button>
      </div>
    </section>
  );
}
