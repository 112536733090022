export default function MobileCoinIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#FF9900" />
      <g clipPath="url(#clip0_3054_34569)">
        <path
          d="M12.2 7.7587C10.1467 7.76536 8.79333 9.3387 8.79333 12.1187C8.79333 14.8987 10.1667 16.4654 12.1867 16.4787C13.6933 16.472 14.82 15.652 15.0867 14.2187L17.86 14.2387C17.56 16.6787 15.5067 18.9054 12.1533 18.9054C8.61333 18.892 6 16.4054 6 12.112C6 7.8187 8.66 5.33203 12.14 5.33203C15.1867 5.33203 17.48 7.07203 17.8467 10.1054H15.0733C14.8533 8.64536 13.7333 7.76536 12.1933 7.75203L12.2 7.7587Z"
          fill="#FFF493"
        />
      </g>
      <defs>
        <clipPath id="clip0_3054_34569">
          <rect
            width="11.8467"
            height="13.56"
            fill="white"
            transform="translate(6 5.33203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
