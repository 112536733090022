import { useState } from "react";
import { useForm } from "react-hook-form";

import { getErrorCode } from "../../domains/auth/errors";
import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { REGEX_FORMAT } from "../../utils/regex";
import PasswordInput from "../inputs/PasswordInput";
import ProfileSaveButton from "../profile/ProfileSaveButton";
import ButtonToast from "../toast/ButtonToast";

type Form = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export default function PasswordForm() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields },
    reset,
    setError,
  } = useForm<Form>();

  const { changePassword } = useAuthActions();
  const [isSuccess, setIsSuccess] = useState(false);

  const isDisabled = Object.keys(dirtyFields).length < 3;

  const onSubmit = async (data: Form) => {
    await changePassword(data.oldPassword, data.confirmNewPassword)
      .then(() => {
        reset();
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 1000);
      })
      .catch((error) => {
        const code = getErrorCode(error);
        if (code === "WRONG_PASSWORD") {
          setError("oldPassword", {
            message: `* ${error.response.data.message}`,
          });
          return;
        }

        if (code === "SAME_PASSWORD") {
          setError("newPassword", {
            message: `* ${error.response.data.message}`,
          });
        }
      });
  };

  return (
    <>
      <form className="px-[18px] md:px-[24px] lg:px-[32px] xl:px-[100px] md:w-[500px] xl:w-full self-center pt-[42px] xl:pt-20 pb-[30px] xl:pb-20 bg-white xl:bg-twinworldGray-150 rounded-2xl">
        <div className="flex flex-col w-full xl:items-center xl:flex-row mb-11">
          <label
            htmlFor="name"
            className="text-[15px] xl:text-lg leading-none w-[310px] font-medium mb-[12px] xl:mb-0"
          >
            Old Password
          </label>

          <PasswordInput
            name="oldPassword"
            register={register}
            placeholder="Type your password"
            error={errors.oldPassword}
            options={{
              required: "* Please check your password",
            }}
          />
        </div>

        <div className="flex flex-col w-full xl:items-center xl:flex-row mb-11">
          <label
            htmlFor="name"
            className="text-[15px] xl:text-lg leading-none w-[310px] font-medium mb-[12px] xl:mb-0"
          >
            New Password
          </label>

          <PasswordInput
            name="newPassword"
            register={register}
            placeholder="Type new password"
            error={errors.newPassword}
            options={{
              required: "* Please enter a new password",
              minLength: { value: 8, message: "* X Minimum 8 letters" },
              pattern: {
                value: REGEX_FORMAT.PASSWORD,
                message: "* X both Alphabets and Numbers",
              },
              maxLength: { value: 20, message: "* X Maximum 20 letters" },
            }}
          />
        </div>

        <div className="flex flex-col w-full xl:items-center xl:flex-row">
          <label
            htmlFor="name"
            className="text-[15px] xl:text-lg leading-none w-[310px] font-medium mb-[12px] xl:mb-0"
          >
            Confirm New Password
          </label>

          <PasswordInput
            name="confirmNewPassword"
            register={register}
            placeholder="Type new password again"
            error={errors.confirmNewPassword}
            options={{
              required: "* Please enter a confirm new password",
              pattern: {
                value: new RegExp(`^(${watch("newPassword")})$`, "i"),
                message: "* The passwords you entered do not match",
              },
              maxLength: { value: 20, message: "* X Maximum 20 letters" },
            }}
          />
        </div>
      </form>
      <div
        className={`relative flex justify-center items-center ${
          errors.confirmNewPassword ? "mt-[24px]" : "mt-0"
        } xl:mt-[50px]`}
      >
        <ProfileSaveButton
          isDisabled={isDisabled}
          onClick={handleSubmit(onSubmit)}
        >
          Confirm
        </ProfileSaveButton>

        <ButtonToast idDisplay={isSuccess} message={"Saved successfully"} />
      </div>
    </>
  );
}
