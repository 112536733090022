import { FieldValues, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { REGEX_FORMAT } from "../../utils/regex";
import ModalActionButton from "../button/ModalActionButton";
import Modal from "../common/Modal";
import ModalClose from "../common/icons/ModalClose";
import LoginInput from "../common/input/LoginInput";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  handleResetPwSuccessModal: () => void;
};

export default function ResetPasswordModal({
  isOpen,
  closeModal,
  handleResetPwSuccessModal,
}: Props) {
  const location = useLocation();

  const { errorMessages, setErrorMessages, resetPassword } = useAuthActions();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const isInputValid =
    Object.keys(dirtyFields).length === 2 && Object.keys(errors).length === 0;

  const onSubmit = (data: FieldValues) => {
    const { password } = data;
    const code = location.search.split("=")[1].split("&")[0];
    const accountId = location.search.split("=")[2];

    resetPassword({ password, code, accountId }, handleResetPwSuccessModal);
  };

  const isPasswordDifferent =
    watch("checking_password")?.length > 0 &&
    watch("password") !== watch("checking_password");

  const handleClickFirstInput = () => {
    setErrorMessages((prevState) => ({
      ...prevState,
      resetPassword: "",
    }));
  };

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-[30px] md:mt-[60px] mb-[26px] md:mb-[42px] text-[20px] md:text-[24px] font-semibold">
      Reset Your password
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[339px] md:max-w-[480px]"
      isOpen={isOpen}
      title={title}
      close={close}
      closeModal={closeModal}
    >
      <form
        className="flex flex-col items-center justify-end w-[279px] md:w-[320px] mx-[30px] md:mx-20 mb-[45px] md:mb-24"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full h-[214px] md:h-auto">
          <div className="w-full">
            <LoginInput
              type="password"
              name="password"
              register={register}
              placeholder="New password"
              options={{
                required: true,
                minLength: {
                  value: 8,
                  message: "* Password should be more than 8 letters",
                },
                pattern: {
                  value: REGEX_FORMAT.PASSWORD,
                  message:
                    "* Ensure that password contains both letters and numbers.",
                },
              }}
              onClick={handleClickFirstInput}
              isError={!!errors.password || !!errorMessages.resetPassword}
            />
            {(errors?.password?.message || errorMessages.resetPassword) && (
              <p className="flex w-full text-left text-twinworldRed text-[13px] md:text-[14px] ml-[12px] md:ml-[18px] mt-[8px] pb-[16px] md:pb-0 md:mb-0">
                {errors?.password?.message?.toString() ||
                  errorMessages.resetPassword.toString()}
              </p>
            )}
          </div>
          <div className="w-full mt-[18px] md:mt-[21px]">
            <LoginInput
              type="password"
              name="checking_password"
              register={register}
              placeholder="Confirm new password"
              options={{
                required: true,
                minLength: {
                  value: 8,
                  message: "* Password should be more than 8 letters",
                },
                pattern: {
                  value: REGEX_FORMAT.PASSWORD,
                  message:
                    "* Ensure that password contains both letters and numbers.",
                },
              }}
              isError={isPasswordDifferent}
            />
            {isPasswordDifferent && (
              <p className="flex w-full text-left text-twinworldRed text-[13px] md:text-[14px] whitespace-nowrap ml-[12px] md:ml-[18px] mt-[8px] pb-[16px] md:pb-0 md:mb-[74px]">
                {"* Please check your password"}
              </p>
            )}
          </div>
        </div>

        <div className="mt-[10px] md:mt-[75px]">
          <ModalActionButton type="submit" isDisabled={!isInputValid}>
            Confirm
          </ModalActionButton>
        </div>
      </form>
    </Modal>
  );
}
