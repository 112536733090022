import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";

import { useAuthActions } from "../../auth/hook/useAuthActions";
import userApi from "../api";
import { PatchInfo, PatchProfile } from "../interface";

interface ErrorMessages {
  changePassword: string;
  changeUsername: string;
}

export const useProfileActions = () => {
  const queryClient = useQueryClient();
  const authActions = useAuthActions();

  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({
    changePassword: "",
    changeUsername: "",
  });

  const variable = useRef({
    isDoubleClick: false,
  });

  const useGetMyProfile = () => {
    const {
      isPending: isMyProfileLoading,
      data: profile,
      refetch: refetchMyProfile,
    } = useQuery({
      queryKey: ["profile"],
      queryFn: userApi.getMyProfile,
      enabled: !!localStorage.getItem("accessToken"),
    });

    return {
      isMyProfileLoading,
      profile,
      refetchMyProfile,
    };
  };

  const useGetUserProfile = (userId: string) => {
    const { isLoading, data, isError } = useQuery({
      queryKey: ["profile", { userId }],
      queryFn: () => userApi.getUserProfile(userId),
    });

    return {
      isLoading,
      isError,
      data,
    };
  };

  const updateUserProfileMutation = useMutation({
    mutationFn: (info: PatchInfo) => userApi.patchUserProfile(info),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
      queryClient.invalidateQueries({ queryKey: ["userSetting"] });
    },
  });

  const updateUserProfile = async (info: PatchInfo) => {
    const { mutateAsync } = updateUserProfileMutation;
    await mutateAsync(info).catch((error) => {
      throw error;
    });
  };

  const patchUserProfile = useMutation({
    mutationFn: async (userProfile: PatchProfile) => {
      await userApi.patchUserProfile(userProfile);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });

      if (!authActions.getLocalStorage("isSignIn")) {
        authActions.setLocalStorage("isSignIn", "true");
      }
    },
  });

  const handleSubmitProfile = async (userSetting: PatchProfile) => {
    try {
      if (variable.current.isDoubleClick) {
        return;
      }

      variable.current.isDoubleClick = true;
      await patchUserProfile.mutate(userSetting);
      variable.current.isDoubleClick = false;
    } catch (err: any) {
      variable.current.isDoubleClick = false;

      if (
        err.response.data.message ===
        "UPDATE_SETTING_ERROR: REGISTERED_NICKNAME"
      ) {
        setErrorMessages((prevState: ErrorMessages) => ({
          ...prevState,
          changeUsername: "Please use different username. Already exist.",
        }));
      }
    }
  };

  return {
    useGetMyProfile,
    useGetUserProfile,
    updateUserProfile,
    handleSubmitProfile,
    errorMessages,
    setErrorMessages,
  };
};
