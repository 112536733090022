import { Fragment } from "react";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import { useInfiniteScrollRef } from "../../hooks/useInfiniteScrollRef";
import ScrollToTopButton from "../common/Button/ScrollToTopButton";
import NoContentIcon from "../common/icons/NoContentIcon";

export default function CoinsUsageHistoryTable() {
  const { useInfiniteCoinsUsageHistory } = useAuthActions();
  const { data, fetchNextPage } = useInfiniteCoinsUsageHistory(50, 0);
  const { targetRef } = useInfiniteScrollRef({ fetchNextPage });
  const { isMobile } = useDeviceBreakPoint();

  return (
    <div className="border-t border-[#EFF4F9] rounded-tl-[10px] rounded-tr-[10px] overflow-hidden w-full">
      {data?.pages[0].length !== 0 ? (
        <table className="w-full">
          <thead>
            <tr className="bg-[#EFF4F9] text-[18px] font-semibold leading-[18px] text-twinworldGray-700 ">
              <th className="w-[248px] text-center py-[22px] px-12">Date</th>
              <th className="w-[685px] text-left py-[22px] px-12">
                Usage Details
              </th>
              <th className="w-[178px] text-center py-[22px] px-[30px]">
                Earn Coins
              </th>
              <th className="w-[178px] text-center py-[22px] px-[30px]">
                Used Coins
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.pages[0].length > 0 &&
              data?.pages.map((page, pageIndex) => (
                <Fragment key={pageIndex}>
                  {page?.map((history, index) => (
                    <tr
                      key={index}
                      className="border-b border-twinworldGray-250"
                    >
                      <td className="font-normal text-center text-twinworldGray-500 text-[16px] leading-4">
                        {history.createdAt}
                      </td>
                      <td className="py-[22px] px-12 font-medium text-left text-twinworldGray-750 text-[16px] leading-4">
                        {history.description}
                      </td>
                      <td className="py-[22px] px-[30px] font-medium text-[16px] leading-4 text-center text-twinworldGray-750">
                        {history.type === "지급"
                          ? `+${history.value.toLocaleString()}C`
                          : "-"}
                      </td>
                      <td className="py-[22px] px-[30px] font-medium text-[16px] leading-4 text-center text-twinworldGray-750">
                        {history.type === "차감"
                          ? `-${history.value.toLocaleString()}C`
                          : "-"}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="flex flex-col items-center mt-40 gap-9">
          <NoContentIcon />
          <div className="text-lg leading-none text-twinworldGray-400">
            You have no coins history.
          </div>
        </div>
      )}

      <div ref={targetRef} />
      {!isMobile && <ScrollToTopButton />}
    </div>
  );
}
