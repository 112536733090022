import { CoinsSummary } from "../../domains/auth/model/CoinsHistory";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import RedeemCard from "../card/RedeemCard";
import CoinIcon from "../common/icons/CoinIcon";
import MobileCoinIcon from "../mobile/icons/MobileCoinIcon";

type Props = {
  coinsSummary: CoinsSummary;
};

export default function RedeemDetails({ coinsSummary }: Props) {
  const { isDesktop } = useDeviceBreakPoint();

  const coinsToNumber = +coinsSummary.currentCoin;

  return (
    <section className="flex min-w-[339px] w-full px-[18px] md:px-[24px] lg:px-[32px] xl:px-0 flex-col gap-0 mx-auto">
      {isDesktop && (
        <h2 className="text-[45px] font-semibold mt-[60px]">
          Redeem Your Coins
        </h2>
      )}
      <div className="flex items-center mt-[36px] xl:mt-[70px] mb-[36px] gap-[21px] xl:gap-9">
        <h3 className="text-[16px] font-medium xl:text-[24px] xl:leading-6 xl:font-semibold xl:text-twinworldGray-600">
          Current Coins
        </h3>
        <div className="flex items-center text-[30px] xl:text-[40px] font-semibold xl:font-medium text-[#FF9900]">
          <div className="inline-block max-w-[149px] truncate">
            {coinsToNumber.toLocaleString()}
          </div>
          <div className="ml-[3px] xl:ml-2">
            {isDesktop ? <CoinIcon /> : <MobileCoinIcon />}
          </div>
        </div>
      </div>
      <ul className="flex flex-col">
        <li>
          <RedeemCard />
        </li>
      </ul>
    </section>
  );
}
