type Props = {
  totalEarnCoins: number;
  totalUsedCoins: number;
};

export default function MobileTotalCoinInfo({
  totalEarnCoins,
  totalUsedCoins,
}: Props) {
  return (
    <div className="flex flex-col gap-[18px] bg-[#EFF4F9] rounded-[8px] min-w-[339px] shadow-[2px_2px_4px_0px_rgba(0,0,0,0.25)] py-6 px-[20px] mt-[36px]">
      <div className="flex items-center">
        <svg
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2" cy="2" r="2" fill="#616161" />
        </svg>
        <span className="ml-2 inline-block text-[14px] font-medium text-twinworldGray-650 w-[140px]">
          Total Earn Coins
        </span>
        <span className="inline-block text-[16px] font-medium">
          {`${
            totalEarnCoins > 0 ? `${totalEarnCoins.toLocaleString()}C` : "-"
          }`}
        </span>
      </div>
      <div className="flex items-center">
        <svg
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2" cy="2" r="2" fill="#616161" />
        </svg>
        <span className="ml-2 inline-block text-[14px] font-medium text-twinworldGray-650 w-[140px]">
          Total Used Coins
        </span>
        <span className="inline-block text-[16px] font-medium">
          {`${
            totalUsedCoins > 0 ? `${totalUsedCoins.toLocaleString()}C` : "-"
          }`}
        </span>
      </div>
    </div>
  );
}
