import { FieldValues, useForm } from "react-hook-form";

import { useAuthActions } from "../../domains/auth/hook/useAuthActions";
import { REGEX_FORMAT } from "../../utils/regex";
import Modal from "../common/Modal";
import LineIcon from "../common/icons/LineIcon";
import ModalClose from "../common/icons/ModalClose";
import LoginInput from "../common/input/LoginInput";
import useModals from "../hooks/useModals";

import EmailConfirmModal from "./EmailConfirmModal";
import SignUpModal from "./SignUpModal";
import { UserEmailSignInInfo } from "./interface";

type Props = {
  closeModal: () => void;
};

export default function EmailSignInModal({ closeModal }: Props) {
  const { emailSignIn, errorMessages, setErrorMessages } = useAuthActions();
  const { openModal, closeModal: closeModals } = useModals();

  const handleOpenResetPwModal = () => {
    closeModals(EmailSignInModal);
    openModal(EmailConfirmModal, {});
  };

  const handleOpenSignUpModal = () => {
    closeModals(EmailSignInModal);
    openModal(SignUpModal, {});
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const emailPattern = REGEX_FORMAT.EMAIL;

  const onSubmit = async (data: FieldValues) => {
    const signInInfo: UserEmailSignInInfo = {
      password: data.password,
      email: data.email,
      serviceName: "Twinworld",
    };

    const isEmail = emailPattern.test(getValues("email"));

    if (isEmail) {
      signInInfo.email = getValues("email");
    }

    await emailSignIn(signInInfo).then(() => {
      window.location.reload();
    });
  };

  const isInputValid =
    Object.keys(dirtyFields).length === 2 && Object.keys(errors).length === 0;

  const close = (
    <div className="flex items-center justify-end pt-[26px] pr-[26px]">
      <button type="button" onClick={closeModal}>
        <ModalClose />
      </button>
    </div>
  );

  const title = (
    <div className="flex justify-center w-full mt-6 mb-14 text-[24px] font-semibold">
      Sign in with Email
    </div>
  );

  return (
    <Modal
      maxWidth="max-w-[480px]"
      title={title}
      close={close}
      closeModal={closeModal}
    >
      <form
        className="flex flex-col items-center gap-4 mx-20 mb-14"
        onSubmit={handleSubmit(onSubmit)}
      >
        <LoginInput
          type="email"
          name="email"
          register={register}
          placeholder="Email"
          options={{
            required: true,
            maxLength: {
              value: 50,
              message: "* X Max length of 50 is allowed",
            },
            pattern: {
              value: emailPattern,
              message: "* Email address must be a valid address",
            },
          }}
          onClick={() => {
            setErrorMessages((prev) => ({
              ...prev,
              accountError: "",
            }));
          }}
          isError={!!errors?.email || !!errorMessages.accountError}
        />
        {(errors?.email || errorMessages.accountError) && (
          <p className="px-[16px] text-twinworldRed text-[14px] text-left w-full flex white-space">
            {errorMessages.accountError || errors?.email?.message?.toString()}
          </p>
        )}

        <LoginInput
          type="password"
          name="password"
          register={register}
          placeholder="Password"
          options={{
            required: true,
            minLength: {
              value: 8,
              message: "* Password should be more than 8 letters.",
            },
            pattern: {
              value: REGEX_FORMAT.PASSWORD,
              message:
                "* Ensure that password contains both letters and numbers.",
            },
          }}
          onClick={() => {
            setErrorMessages((prev) => ({
              ...prev,
              wrongPassword: "",
            }));
          }}
          isError={
            !!errors?.password ||
            !!errorMessages.wrongPassword ||
            !!errorMessages.accountError
          }
        />
        {(errors?.password || errorMessages.wrongPassword) && (
          <p className="px-[16px] text-twinworldRed text-[14px] leading-5 text-left w-full flex white-space">
            {errorMessages.wrongPassword ||
              errors?.password?.message?.toString()}
          </p>
        )}
        <button
          type="submit"
          className="flex items-center justify-center w-[320px] h-[58px] px-[100px] py-[21px] border-2 border-black bg-twinworldPrimary-200 rounded-full mt-[77px] text-[16px] font-semibold 
          hover:bg-black hover:text-twinworldPrimary-200 disabled:border-2 disabled:border-twinworldGray-500 disabled:bg-[#D7E0ED] disabled:text-twinworldGray-500 disabled:text-[16px] disabled:font-semibold disabled:cursor-not-allowed"
          disabled={!isInputValid}
        >
          Sign In
        </button>
        <div className="flex flex-row items-center mt-[25px] text-[14px]">
          <span
            role="button"
            className="mr-6 text-black cursor-pointer"
            onClick={handleOpenResetPwModal}
          >
            Forgot my Password
          </span>
          <LineIcon height={18} />
          <span
            role="button"
            className="ml-6 text-black cursor-pointer"
            onClick={handleOpenSignUpModal}
          >
            Sign up
          </span>
        </div>
      </form>
    </Modal>
  );
}
