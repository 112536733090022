type Props = {
  totalEarnCoins: number;
  totalUsedCoins: number;
};

export default function TotalCoinInfo({
  totalEarnCoins,
  totalUsedCoins,
}: Props) {
  return (
    <div className="flex gap-3">
      <span className="text-[18px] leading-[18px] font-medium text-twinworldGray-600">
        Total Earn Coins
      </span>
      <span className="text-[18px] leading-[18px] font-semibold">
        {`${totalEarnCoins > 0 ? `${totalEarnCoins.toLocaleString()}C` : "-"}`}
      </span>
      <span className="w-[1px] h-4 bg-twinworldGray-250 mx-[24px]" />
      <span className="text-[18px] leading-[18px] font-medium text-twinworldGray-600">
        Total Used Coins
      </span>
      <span className="text-[18px] leading-[18px] font-semibold">
        {`${totalUsedCoins > 0 ? `${totalUsedCoins.toLocaleString()}C` : "-"}`}
      </span>
    </div>
  );
}
