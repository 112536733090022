import { CoinsSummary as CoinsSummaryType } from "../../domains/auth/model/CoinsHistory";
import useDeviceBreakPoint from "../../hooks/useDeviceBreakPoint";
import CoinIcon from "../common/icons/CoinIcon";
import MobileTotalCoinInfo from "../mobile/MobileTotalCoinInfo";
import MobileCoinIcon from "../mobile/icons/MobileCoinIcon";

import TotalCoinInfo from "./TotalCoinInfo";

type Props = {
  coinsSummary: CoinsSummaryType;
};

export default function CoinsSummary({ coinsSummary }: Props) {
  const { currentCoin, totalEarnCoins, totalUsedCoins } = coinsSummary;

  const { isDesktop } = useDeviceBreakPoint();

  const coinsToNumber = +currentCoin;
  const totalEarnCoinsToNumber = +totalEarnCoins;
  const totalUsedCoinsToNumber = +totalUsedCoins;

  return (
    <section className="flex xl:items-center xl:justify-between flex-col xl:flex-row mt-[36px] xl:mt-[70px] xl:mb-[35px]">
      <div className="flex items-center">
        <span className="text-[16px] font-medium xl:text-[24px] xl:leading-6 xl:font-semibold xl:text-twinworldGray-600">
          Current Coins
        </span>
        <span className="inline-block truncate max-w-[149px] ml-[21px] xl:ml-9 text-[30px] xl:text-[40px] font-semibold xl:font-medium text-[#ff9900]">
          {coinsToNumber.toLocaleString()}
        </span>
        <div className="ml-[3px] xl:ml-2">
          {isDesktop ? <CoinIcon /> : <MobileCoinIcon />}
        </div>
      </div>
      {isDesktop ? (
        <TotalCoinInfo
          totalEarnCoins={totalEarnCoinsToNumber}
          totalUsedCoins={totalUsedCoinsToNumber}
        />
      ) : (
        <MobileTotalCoinInfo
          totalEarnCoins={totalEarnCoinsToNumber}
          totalUsedCoins={totalUsedCoinsToNumber}
        />
      )}
    </section>
  );
}
